body {
  margin: 0;
  width: 100dvw;
  height: 100dvh;
}

p {
  hyphens: auto;
  hyphenate-limit-chars: 5 3 3;
  -webkit-hyphenate-limit-before: 3;
  -webkit-hyphenate-limit-after: 3;
  -ms-hyphenate-limit-chars: 5 3 3;

  &,
  _:-moz-only-whitespace {
    hyphens: none;
  }
}

a {
  text-decoration: none;
  color: unset;

  h3,
  p {
    display: inline;
  }
}

ul {
  list-style-type: none;
}

p,
h1,
h2,
h3,
h4,
ul {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4 {
  text-transform: uppercase;
}

button {
  all: unset;
  cursor: pointer;
}

@font-face {
  font-family: "Recursive";
  src: url("./assets/fonts/Recursive/Recursive.woff2");
}

@font-face {
  font-family: "Manifont Grotesk";
  font-weight: bold;
  font-style: normal;
  src: url("./assets/fonts/ManifontGrotesk/ManifontGroteskBold.woff");
}

@font-face {
  font-family: "Manifont Grotesk";
  font-weight: bold;
  font-style: italic;
  src: url("./assets/fonts/ManifontGrotesk/ManifontGroteskBoldItalic.woff");
}

@font-face {
  font-family: "Manifont Grotesk";
  font-weight: normal;
  font-style: normal;
  src: url("./assets/fonts/ManifontGrotesk/ManifontGroteskBook.woff");
}

@font-face {
  font-family: "Manifont Grotesk";
  font-weight: normal;
  font-style: italic;
  src: url("./assets/fonts/ManifontGrotesk/ManifontGroteskBookItalic.woff");
}